import React from "react";
import CosmoDB from "../api/cosmodb";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

function Contact() {
  const recaptcha = useRef(null);
  const [formStatus, setFormStatus] = React.useState("Send");
  const onSubmit = async (e: any) => {
    const captchaValue = "";
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      // make form submission
      alert("Form submission successful!");
    }
    e.preventDefault();
    setFormStatus("Submitting...");
    const { name, email, message, phoneNumber } = e.target.elements;
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
      phoneNumber: phoneNumber.value,
      //resume: new File()//new File(resume.value, name.value)
    };
    console.log(conFom);
    const cosmodb = new CosmoDB();
    const response = await cosmodb.postClientMessage(conFom);
    console.log(response);
    setFormStatus("Sent");
  };
  return (
    <div className="contact-container">
      <div className="row">
        <div className="col-md-6 col-xs-12">
          <h2 className="mb-3">We would love to assist you!</h2>
          <h3>
            Please fill out this form and we will get in touch with you shortly!
          </h3>
          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <input className="form-control" type="text" id="name" required />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="phoneNumber">
                Phone Number
              </label>
              <input className="form-control" type="tel" id="phoneNumber" />
            </div>
            <div className="mb-3  ">
              <label className="form-label" htmlFor="message">
                Message
              </label>
              <textarea className="form-control" id="message" required />
            </div>
            <ReCAPTCHA
              ref={recaptcha}
              sitekey={process.env.REACT_APP_SITE_KEY!}
            />
            <button className="btn btn-success" type="submit">
              {formStatus}
            </button>
          </form>
        </div>
        <div className="col-md-6 col-xs-12 contact-info-container">
          <a className="phone-link" href="tel:305-471-0262">
            {" "}
            <i className="fa-solid fa-phone"> </i> 305-471-0262
          </a>
          <a
            className="main-link "
            href="mailto: optimalcarehomehealth@gmail.com"
          >
            <i className="fa-solid fa-envelope"></i>{" "}
            optimalcarehomehealth@gmail.com
          </a>
          <br />
          <br />
          <div className="map-container">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3588.7293574341593!2d-80.29942282436794!3d25.911266102408323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9a5499aeaaaab%3A0x4a43a70f7df89fd1!2s5901%20NW%20151st%20St%20Suite%20211%2C%20Miami%20Lakes%2C%20FL%2033014!5e0!3m2!1sen!2sus!4v1699248540558!5m2!1sen!2sus"
              height="400"
              width={document.documentElement.clientWidth > 600 ? 400 : 300}
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
