


function Insurances() {
  
    return (
      <div >
          <h1>This page is under development. </h1>
        </div>
    );
  }


export default Insurances;