import "./Home.css";

function Home() {
  return (
    <div className="home-section">
      <div className="home-content">
        <h1>Welcome to Optimal Care Home Health Agency!</h1>
        <p>
          Optimal Care is a privately owned, AHCA licensed, Medicare Certified,
          ACHC Accredited and insured Home Health Agency operating locally
          throughout Miami Dade Conty. We are committed with patients to improve
          their quality of life by providing excellence in services in the
          comfort of their home. Our key management experience exceeds decades,
          allowing us to provide professional, reliable, and compassionate care
          to the communities we serve.
        </p>
      </div>
    </div>
  );
}

export default Home;
