import React from "react";
import CosmoDB from "../api/cosmodb";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import Form from "react-bootstrap/Form";
import "./Contact.css";
import { SPECIALTYS } from "../values";
import "./Careers.css";
function Careers() {
  const recaptcha = useRef(null);
  const [formStatus, setFormStatus] = React.useState("Send");
  const onSubmit = async (e: any) => {
    const captchaValue = "";
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      // make form submission
      alert("Form submission successful!");
    }
    e.preventDefault();
    setFormStatus("Submitting...");
    const { name, email, message, specialty, phoneNumber, interestLocation } =
      e.target.elements;
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
      specialty: specialty.value,
      phoneNumber: phoneNumber.value,
      interestLocation: interestLocation.value,
      //resume: new File()//new File(resume.value, name.value)
    };
    console.log(conFom);
    const cosmodb = new CosmoDB();
    const response = await cosmodb.postJobApplication(conFom);
    console.log(response);
    setFormStatus("Sent");

    const resume = document.getElementById("resume") as HTMLInputElement;

    if (resume !== null && resume.files !== null) {
      var data = new FormData();
      data.append("file", resume.files![0]);

      cosmodb.postResume(data, conFom.email);
    }
  };
  return (
    <div className="careers-container">
      <div className="careers-content">
        <h2>Are you interested in working with us? Contact us</h2>
        <form className="row" onSubmit={onSubmit}>
          <div className="col-md-6 col-xs-12">
            <div>
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <input className="form-control" type="text" id="name" required />
            </div>
            <div>
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                required
              />
            </div>
            <div>
              <label className="form-label" htmlFor="interestLocation">
                Zone of interest
              </label>
              <input
                className="form-control"
                type="text"
                id="interestLocation"
                required
              />
            </div>
            <div>
              <label className="form-label" htmlFor="phoneNumber">
                Phone Number
              </label>
              <input className="form-control" type="tel" id="phoneNumber" />
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div>
              <label className="form-label" htmlFor="resume">
                Resume/CV
              </label>
              <input
                onChangeCapture={(e) => console.log(e)}
                className="form-control"
                type="file"
                id="resume"
              />
            </div>
            <div>
              <label className="form-label" htmlFor="message">
                Message
              </label>
              <textarea className="form-control" rows={4} id="message" required />
            </div>
            <div>
              <label className="form-label" htmlFor="specialty">
                Specialty
              </label>
              <Form.Select
                className="select"
                id="specialty"
                aria-label="specialty select"
              >
                <option value={""}>Specialty</option>
                {SPECIALTYS.map((specialty) => (
                  <option key={specialty} value={specialty}>
                    {specialty}
                  </option>
                ))}
              </Form.Select>
            </div>
            <ReCAPTCHA
              style={{ marginTop: "10px" }}
              ref={recaptcha}
              sitekey={process.env.REACT_APP_SITE_KEY!}
            />
            <button className="btn btn-success" type="submit">
              {formStatus}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Careers;
