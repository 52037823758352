import CosmoDB from "../api/cosmodb";
import { useEffect, useState } from "react";
import { JobApplication } from "../models/message";
import "./ManageApplications.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SPECIALTYS } from "../values";
import Nav from "react-bootstrap/Nav";
import { useAuth0 } from "@auth0/auth0-react";

function ManageApplications() {
  const [jobApplications, setJobApplications] = useState(
    [] as JobApplication[],
  );
  const [messageType, setMessageType] = useState("jobApplication");
  const [refresh, setRefresh] = useState(0);
  const [filters, setFilters] = useState({
    specialty: "",
    time: "",
    name: "",
    zone: "",
  });
  const [filteredMessages, setFilteredMessages] = useState(
    [] as JobApplication[],
  );
  const {
    user,
    loginWithRedirect,
    getIdTokenClaims,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const [applicationIndex, setApplicationIndex] = useState(-1);
  const database = new CosmoDB();
  const [notes, setNotes] = useState("");
  useEffect(() => {
    if (messageType === "jobApplication")
      database.getJobApplications().then((response) => {
        setJobApplications(response);
        setFilteredMessages(response);
      });
    else {
      database.getClientMessages().then((response) => {
        setJobApplications(response);
        setFilteredMessages(response);
      });
    }
  }, [refresh]);

  useEffect(() => {
    setFilteredMessages(jobApplications.filter(filterMessage));
  }, [filters]);
  function formatDate(date = new Date()) {
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", {
      month: "2-digit",
    });
    const day = date.toLocaleString("default", { day: "2-digit" });

    return [year, month, day].join("-");
  }

  function download(url: string | undefined, name: string) {
    if (url === undefined) {
      alert("User didn't submit a resume");
      return;
    }
    var link = document.createElement("a");
    link.href = url!;
    console.log(name);
    link.download = encodeURIComponent(name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function CalculateDate(date: string, days: number) {
    if (days === 0) return date;
    const dates = date.split("-");
    dates[2] = (parseInt(dates[2]) - days).toString();
    if (parseInt(dates[2]) < 1) {
      dates[2] = (parseInt(dates[2]) + 30).toString();
      dates[1] = (parseInt(dates[1]) - 1).toString();
      if (parseInt(dates[1]) < 1) {
        dates[1] = "12";
        dates[0] = (parseInt(dates[0]) - 1).toString();
      }
    }
    if (dates[1].length === 1) dates[1] = "0" + dates[1];
    if (dates[2].length === 1) dates[2] = "0" + dates[2];
    console.log(dates.join("-"));
    return dates.join("-");
  }
  function filterMessage(message: JobApplication) {
    if (filters.specialty !== "" && filters.specialty !== message.specialty)
      return false;
    if (
      message.name !== "" &&
      message.name !== undefined &&
      !message.name.toLowerCase().includes(filters.name.toLowerCase())
    )
      return false;
    console.log(filters.time);
    console.log(message.created_at);
    console.log(message.created_at!.split(" ")[0]);
    if (
      filters.time !== "" &&
      filters.time !== "-1" &&
      CalculateDate(formatDate(new Date()), parseInt(filters.time)) >
        message.created_at!.split(" ")[0]
    )
      return false;
    if (
      filters.zone !== "" &&
      filters.zone !== undefined &&
      !message.interestLocation
        ?.toLocaleLowerCase()
        .includes(filters.zone.toLocaleLowerCase())
    )
      return false;
    return true;
  }
  async function deleteMessage(email: string, id?: string) {
    try {
      if (messageType === "jobApplication")
        await database.deleteJobApplication(email).then(() => {
          setRefresh(refresh + 1);
        });
      else
        await database.deleteClientMessage(email, id!).then(() => {
          setRefresh(refresh + 1);
        });
    } catch (error) {
      console.log(error); // catches both errors
    }
  }
  console.log(user);
  if (!(user && user.email === "miabitsolutions@gmail.com")) {
    //&& roles.includes('OptimalCareAdmin')
    return (
      <div>
        <div>Access denied. You are not a company administrator.</div>
        <button onClick={() => loginWithRedirect()}>Log In</button>
      </div>
    );
  }
  if (applicationIndex === -1)
    return (
      <div>
        <Container style={{ marginTop: "20px" }}>
          <Row>
            <Nav variant="tabs" defaultActiveKey="/home">
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    setMessageType("jobApplication");
                    setRefresh(refresh + 1);
                  }}
                >
                  Job Application
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    setMessageType("clientMessage");
                    setRefresh(refresh + 1);
                  }}
                >
                  Client Messages
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
          <br />
          <Row>
            <Col>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Name
                </InputGroup.Text>
                <Form.Control
                  onChange={(e) => {
                    setFilters({
                      time: filters.time,
                      specialty: filters.specialty,
                      name: e.target.value,
                      zone: filters.zone,
                    });
                  }}
                  aria-label="Name Input"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Zone
                </InputGroup.Text>
                <Form.Control
                  onChange={(e) => {
                    setFilters({
                      time: filters.time,
                      specialty: filters.specialty,
                      name: filters.name,
                      zone: e.target.value,
                    });
                  }}
                  aria-label="Zone Input"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </InputGroup>
            </Col>
            <Col>
              <Form.Select
                onChange={(e) => {
                  setFilters({
                    time: filters.time,
                    specialty: e.target.value,
                    name: filters.name,
                    zone: filters.zone,
                  });
                }}
                aria-label="specialty select"
              >
                <option value={""}>Specialty</option>
                {SPECIALTYS.map((specialty) => (
                  <option key={specialty} value={specialty}>
                    {specialty}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <Form.Select
                onChange={(e) => {
                  setFilters({
                    time: e.target.value,
                    specialty: filters.specialty,
                    name: filters.name,
                    zone: filters.zone,
                  });
                }}
                aria-label="time select"
              >
                <option value={-1}>Any Time</option>
                <option value={30}>This Month</option>
                <option value={6}>This Week</option>
                <option value={0}>Today</option>
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Message</th>
                  <th scope="col">Created_at</th>
                  <th scope="col">Delete</th>
                  {messageType === "jobApplication" && (
                    <>
                      <th scope="col">Specialty</th>
                      <th scope="col">Location </th>
                      <th scope="col">Download Resume</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredMessages.map((message, index) => (
                  <tr key={index}>
                    <th scope="row">{index}</th>
                    <td>{message.name}</td>
                    <td onClick={() => setApplicationIndex(index)}>
                      {message.email}
                    </td>
                    <td>{message.phoneNumber}</td>
                    <td>{message.message}</td>
                    <td>{message.created_at?.split(" ")[0]}</td>
                    {messageType === "jobApplication" && (
                      <td
                        onClick={() => {
                          deleteMessage(message.email);
                        }}
                      >
                        {" "}
                        <i className="fa-solid fa-trash"></i>{" "}
                      </td>
                    )}
                    {messageType !== "jobApplication" && (
                      <td
                        onClick={() => {
                          deleteMessage(message.email, message.id);
                        }}
                      >
                        {" "}
                        <i className="fa-solid fa-trash"></i>{" "}
                      </td>
                    )}
                    {messageType === "jobApplication" && (
                      <>
                        <td>{message.specialty}</td>
                        <td>{message.interestLocation}</td>
                        <td>
                          <i
                            onClick={() =>
                              download(message.url, message.name + "_Resume")
                            }
                            className="fa-solid fa-download"
                          ></i>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>
        </Container>
      </div>
    );
  else {
    return (
      <div className="container">
        <div className="row close-button">
          <button
            onClick={() => {
              setApplicationIndex(-1);
              setRefresh(refresh + 1);
            }}
            className="btn btn-danger"
          >
            Close
          </button>
        </div>
        <div className="row">
          <div className="col">
            <p>Full Name</p>
            <p>{jobApplications[applicationIndex].name}</p>
          </div>
          <div className="col">
            <p>Email</p>
            <p>{jobApplications[applicationIndex].email}</p>
          </div>
          <div className="col">
            <p>Phone Number</p>
            <p>{jobApplications[applicationIndex].phoneNumber}</p>
          </div>
          <div className="col">
            <p>Email</p>
            <p>{jobApplications[applicationIndex].email}</p>
          </div>
          <div className="col">
            <p>message</p>
            <p>{jobApplications[applicationIndex].message}</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label className="form-label" htmlFor="phoneNumber">
                Notes:
              </label>
              <input
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                className="form-control"
                type="tel"
                id="phoneNumber"
                placeholder={jobApplications[applicationIndex].notes}
              />
            </div>
          </div>

          <div className="col update-button">
            <button
              onClick={() => {
                database.postJobApplication({
                  ...jobApplications[applicationIndex],
                  notes: notes,
                });
              }}
              className="btn btn-success "
            >
              {" "}
              Update notes
            </button>
          </div>
        </div>
        <div className="row">
          <button
            onClick={() => {
              database.blacklistApplicant(
                jobApplications[applicationIndex].email,
              );
            }}
            className="btn btn-danger"
          >
            Blacklist Applicant.
          </button>
        </div>
      </div>
    );
  }
}

export default ManageApplications;
