export const SPECIALTYS= [

    "Home Health Aide (HHA)",
    "Certified Nursing Assistant (CNA)",
    "Licensed Practical Nurses (LPN)",
    "Registered Nurses (RN)",
    "Physical Therapy (PT)",
    "Physical Therapy Assistant (PTA)",
    "Occupational Therapy (OT)",
    "Occupational Therapy Assistant (OTA)",
    "Speech Therapy (ST)",
    "Medical Social Worker (MSW)",

]