import "./Services.css";

function Services() {
  return (
    <div className="services-container">
      <div className="services-content">
      <div className="row">
        <p style={{ fontSize: "1.2rem" }}>
          Safety, nutrition, mobility, and diseases are major factors that
          impact our elderly ability to live independently. Optimal Care Home
          Health Agency provide a variety of services from our trained home care
          staff with the goal of prevent hospitalization and helping the patient
          regain independence to become as self-sufficient as possible while
          also managing their disease or condition.
        </p>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 card-container">
          <div className=" card">
            <div className="card-body">
              <h5 className="card-title">Skilled Nursing Care:</h5>
              <p className="card-text">
                This is a medical care service prescribed by a doctor and
                provided in patient’s home by medical professionals. It is
                likely temporary as the patient is recovering from an injury or
                an illness.
              </p>
              <a href="/skilled-nursing-care" className="btn btn-success">
                Learn More
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 card-container">
          <div className=" card">
            <div className="card-body">
              <h5 className="card-title">
                Personal Care (Non-skilled Nursing Care):
              </h5>
              <p className="card-text">
                This is a non-medical care service provided by Home Health Aides
                (HHA) and Certified Nursing Assistance (CNA) to patients who
                require assistance with daily tasks for keeping them safe and
                comfortable in their own home. The frequency of this service
                depend of the patient’s needs and may vary from daily to weekly
                visits to provide the following services:
              </p>
              <a href="/non-skilled-nursing-care" className="btn btn-success">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Services;
