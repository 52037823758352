import "./Service.css";

function Service(props: any) {
  if (props.name === "skilled-nursing-care")
    return (
      <div className="service-container">
        <div className="service-content">
          <p>
            {" "}
            Skilled Nursing Care: This is a medical care service prescribed by a
            doctor and provided in patient’s home by medical professionals. It
            is likely temporary as the patient is recovering from an injury or
            an illness. Skilled Nursing Skilled Therapy Home Health Aide Medical
            Social Workers
          </p>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className=" card">
                <div className="card-body">
                  <h5 className="card-title">Skilled Nursing</h5>
                  <p className="card-text">
                    Our Registered Nurses (RNs) and Licensed Practical Nurses
                    (LPNs) have a foundational passion for providing the proper
                    care to patients. They will assess and identify patient
                    treatment needs to create a unique plan of care according to
                    the physician order ensuring to every patient the best
                    quality of care while receiving services such as:
                  </p>
                  <a
                    href="/services/skilled-nursing-care"
                    className="btn btn-success"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className=" card">
                <div className="card-body">
                  <h5 className="card-title">Skilled Therapy</h5>
                  <p className="card-text">
                    Our PTs and PTAs work one-on-one with patients in their own
                    environment improving their endurance, strength, and range
                    of motion. They focus not only in rehabilitation but also on
                    the prevention of pain and future injury making services
                    completely relevant to the patient's needs and goals. Some
                    of these services can be exercise programs, gait training,
                    instruction on use of wheelchairs, walkers and canes.
                  </p>
                  <a href="#" className="btn btn-success">
                    Go somewhere
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className=" card">
                <div className="card-body">
                  <h5 className="card-title">Home Health Aide</h5>
                  <p className="card-text">
                    This is a medical care service prescribed by a doctor and
                    provided in patient’s home by medical professionals. It is
                    likely temporary as the patient is recovering from an injury
                    or an illness.
                  </p>
                  <a
                    href="/services/skilled-nursing-care"
                    className="btn btn-success"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className=" card">
                <div className="card-body">
                  <h5 className="card-title">Medical Social Workers</h5>
                  <p className="card-text">
                    This is a medical care service prescribed by a doctor and
                    provided in patient’s home by medical professionals. It is
                    likely temporary as the patient is recovering from an injury
                    or an illness.
                  </p>
                  <a href="#" className="btn btn-success">
                    Go somewhere
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else {
    return (
      <div className="service-container">
        <div className="service-content">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                Personal Care (Non-skilled Nursing Care):
              </h5>
              <p className="card-text">
                This is a non-medical care service provided by Home Health Aides
                (HHA) and Certified Nursing Assistance (CNA) to patients who
                require assistance with daily tasks for keeping them safe and
                comfortable in their own home. The frequency of this service
                depend of the patient’s needs and may vary from daily to weekly
                visits to provide the following services: - Personal care -
                Bathing and toileting - Assisting with grooming, and other
                hygiene needs, like brushing their teeth - Helping get dressed -
                Companion - Transfer and ambulation - Meal preparation - Light
                housekeeping - Respite to alleviate the caregiver - Medication
                reminders - Grocery shopping - Laundry
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
