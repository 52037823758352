import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './views/Home';
import Contact from './views/Contact'
import Insurances from './views/Insurances';
import Services from './views/Services';
import Service from './views/Service';
import Careers from './views/Careers';
import NotFound from './views/NotFound'
import NavBar from './components/NavBar';
import ManageApplications from './views/ManageApplications';
import Auth0ProviderWithHistory from './components/Auth0Provider';
import 'bootstrap'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {
  createBrowserRouter,
  RouterProvider,
  
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>
  },
  {
    path: "/home",
    element: <Home/>
  },
  {
    path: "/contact-us",
    element: <Contact/>
  },
  {
    path: "/careers",
    element: <Careers/>
  },
  {
    path: "/Insurances",
    element: <Insurances/>
  },
  {
    path: "/services",
    element: <Services/>
  },
  {
    path:"/skilled-nursing-care",
    element: <Service name="skilled-nursing-care"/>
  },
  {
    path:"/non-skilled-nursing-care",
    element: <Service name ="non-skilled-nursing-care"/>
  },
  {
    path: "/admin",
    element: <ManageApplications/>
  },
  {
    path: "/*",
    element: <NotFound/>
  },
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0ProviderWithHistory>
      <NavBar/>
      <RouterProvider router={router} />
    </Auth0ProviderWithHistory>
  </React.StrictMode>
);


