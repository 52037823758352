import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './NavBar.css'
function NavegationBar() {
    return (
        <Navbar expand="lg" className="navbar-container">
            <Container className='transparent' id='navbar'>
                <Navbar.Brand href="/home" className='transparent' >
                    <img className="navbar-image" src = "./../../logo.svg" alt="brand logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="nav-options">
                        <Nav.Link href="/home"><span id='homeItem' >Home</span></Nav.Link>
                        <Nav.Link href="/services"><span id='servicesItem'>Services</span></Nav.Link>
                        <Nav.Link href="/careers"><span id='careersItem'>Careers</span></Nav.Link>
                        <Nav.Link href="/contact-us"><span id='contactITem'>Contact Us</span></Nav.Link>
                        <Nav.Link href="/insurances"><span id='insurancesItem'>Insurances</span></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
  }
  
  export default NavegationBar;

  

