import { Console } from 'console'
import {JobApplication,ClientMessage} from '../models/message'
import axios, { AxiosInstance } from "axios"
export default class CosmoDB
{
    
    request: AxiosInstance
    constructor()
    {
        this.request = axios.create(
            {
                baseURL:"https://496b79r7tg.execute-api.us-east-2.amazonaws.com/api/v1",
                timeout: 3000
            }
        )
    }
    postJobApplication = async ( message : JobApplication )=>
    {
        const response = await this.request({
            url: "/job-application",
            method:"POST",
            data: message
        })
        return response;
    }
    blacklistApplicant = async ( email: string)=>
    {
        const response = await this.request(
            {
                url: "/black-list/"+email,
                method: "PUT",
                data:{ status: "blocked"}
            }
        )
        return response;
    }

    postResume = async ( resume_content : any, email : string )=>
    {
        const response = await this.request(
            {
            url: "/attachment/job-application/" + email,
            method: "POST",
            data: resume_content
            }
        )
        return response;
    }
    getJobApplications = async() =>
    {
        const response = await this.request({
            url: "/job-application",
            method:"GET",
        })
        return response.data;
    }
    deleteJobApplication = ( email : string) =>
    {
        return this.request({
            url: "/job-application/"+email,
            method:"DELETE",
        })
    }


    postClientMessage = async ( message: ClientMessage )=>
    {
        const response = await this.request({
            url: "/client-message",
            method:"POST",
            data: message
        })
        return response;
    }
    getClientMessages = async(  ) =>
    {
        const response = await this.request({
            url: "/client-message",
            method:"GET",
        })
        return response.data;
    }
    deleteClientMessage = ( email: string, id: string) =>
    {
        return this.request({
            url: "https://496b79r7tg.execute-api.us-east-2.amazonaws.com/api/v1/client-message/" +email+"/"+id.slice(3),
            method:"DELETE",
        })
    }
}
